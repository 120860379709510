
.amounts .cart-product-name {
    text-transform: uppercase;
}


/* We remove the line below all amounts in recap of checkout page */
.amounts tr:last-child .cart-product-name {
    border-bottom-width: 0px;
}



.shippingPrice ul {
    padding-left: 0;
    margin-left: 1.75em;
    list-style-type:disc;
    margin-bottom:0px;
}
.shippingPrice ul li span.free {
    font-weight:600;
    color: #28a745
}
.shippingPrice {
    margin-top:5px;
    padding:10px;
    border: 0px;
    border-bottom: 1px solid rgba(0,0,0,0.075);
    box-shadow: 0 0 10px 0px rgba(0,0,0,.1);
}
.shippingPrice .country {
    margin-top:10px;
}
.shippingPrice .country:first-child {
    margin-top:0px;
}
.shippingPrice .country > span {
    display:block;
}
.shippingPrice .location {
    margin-left: 1.75em;
    margin-bottom: 5px;
}
.shippingPrice .location:last-child {
    margin-bottom:0px;
}



.shippingPriceCollapse > a i:last-child {
    margin-left:5px;
    transition: 0.3s ease all;
}
.shippingPriceCollapse > a.opened i:last-child {
    transform: rotate(180deg);
}




/*
.address .table td.header {
    padding-right:15px;
}

.address .table td {
    border-top:0px;
    white-space: nowrap;
    padding:0px;
}
*/



.delivery::after {
    content: '';
    display: block;
    margin: 10px 30px 20px 30px;
    border-top: 1px solid var(--secondary-color);
}
.delivery:last-child::after {
    display:none;
}
.delivery .title {
    display: block;
    font-weight: 600;
    font-size: 18px;
    color: var(--secondary-color);
    margin-bottom:10px;
}
.delivery .warning, .delivery .note {
      display:block;
}

/* We add space between the delivery note and the specific mod information */
.delivery .note {
    margin-bottom: 1rem;
}
.delivery .warning, .delivery .prices, .delivery .details {
    margin-top:5px;
}
.delivery .pickup {
      margin-top:5px;
}
.delivery .pickup > span {
      font-weight: 600;
}
.delivery .pickup address {
      margin-bottom:5px;
}
.delivery .prices {
      font-weight: 600;
}



.delivery-type.row {
    margin-left:0px;
    margin-right:0px;
}
.delivery-type:first-child > div {
    border:0px;
}
.delivery-type > div {
    border-top: 2px solid #dee2e6;
    padding: 0.75rem;
}
@media (max-width: 767.98px) {
.delivery-type > div:last-child {
        border-top:0px;
}
}



.card-panel .card-number {
  margin-bottom:15px;
}
@media (max-width: 575.98px) {
.card-panel .card-expiry.card-cvc > div {
    margin-bottom:15px;
}
}




.payment-item.row {
    margin-left:0px;
    margin-right:0px;
}
.payment-item:first-child > div {
    border:0px;
}
.payment-item > div {
    border-top: 2px solid #dee2e6;
    padding: 0.75rem;
}
@media (max-width: 767.98px) {
.payment-item > div:last-child {
        border-top:0px;
}
}
.payment-card .col_full.card-panel {
    margin-bottom: 20px!important;
}
.payment-card .logo {
    display:block;
    margin-left: auto;
    height: 35px;
    float: right;
}
.payment-card .logo img {
    height: 35px;
}
.payment-card .logo img:not(:last-child) {
    margin-right:10px;
}



.promo-codes {
     margin-bottom: 20px;
}
.promo-codes .applied-codes {
     margin-top: 20px;
}
.promo-codes .applied-codes span.code {
    font-weight: 600;
    color: #28a745
}
.promo-codes .applied-codes span.note {
     margin-left:10px;
}
@media (max-width: 767.98px) {
.promo-codes .button.add-promo{
        margin-top: 10px!important;
}
}



.payment .conditions {
  margin-top:30px;
  margin-bottom: 20px;
}
.payment .conditions .pretty {
  display:inline-block;
  width: 20px;
}
.button.payment {
  width: 100%;
}



.delivery-step .pretty .state label:before, .delivery-step .pretty .state label:after,
.payment-step .pretty .state label:before, .payment-step .pretty .state label:after {
    height:20px;
    width:20px;
    top:0px;
}
.delivery-step .pretty,
.payment-step .pretty {
    line-height: 20px;
    display: block;
}
@media (max-width: 991.98px) {
.delivery-step .pretty, 
  .payment-step .payment-item .pretty {
    white-space: normal;
    min-width: 180px;
}
}
@media (max-width: 767.98px) {
.delivery-step .pretty, 
  .payment-step .payment-item .pretty {
    white-space: normal;
    min-width: auto;
}
}
.delivery-step .pretty:hover .state label,
.payment-step .pretty:hover .state label {
    color: var(--secondary-color) !important;
}
.delivery-step .pretty .state label,
.payment-step .pretty .state label {
    margin-left: 25px;
}

